/* IE */
@font-face {
	font-family: 'Ubuntu Mono';
	font-style: italic;
	font-weight: 400;
	src: local('Ubuntu Mono Italic'), local('UbuntuMono-Italic'), url(https://fonts.gstatic.com/s/ubuntumono/v9/KFOhCneDtsqEr0keqCMhbCc_OsbSlg.woff) format('woff');
}
@font-face {
	font-family: 'Ubuntu Mono';
	font-style: normal;
	font-weight: 400;
	src: local('Ubuntu Mono'), local('UbuntuMono-Regular'), url(https://fonts.gstatic.com/s/ubuntumono/v9/KFOjCneDtsqEr0keqCMhbCc6CsI.woff) format('woff');
}
@font-face {
	font-family: 'Ubuntu Mono';
	font-style: normal;
	font-weight: 700;
	src: local('Ubuntu Mono Bold'), local('UbuntuMono-Bold'), url(https://fonts.gstatic.com/s/ubuntumono/v9/KFO-CneDtsqEr0keqCMhbC-BL9H1tYs.woff) format('woff');
}

/* Chrome */
@font-face {
	font-family: 'Ubuntu Mono';
	font-style: normal;
	font-weight: 400;
	src: local('Ubuntu Mono'),local('UbuntuMono-Regular'),url(https://fonts.gstatic.com/s/ubuntumono/v9/KFOjCneDtsqEr0keqCMhbCc3CsTYl4BOQ3o.woff2) format('woff2');
	unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F
}
@font-face {
	font-family: 'Ubuntu Mono';
	font-style: normal;
	font-weight: 400;
	src: local('Ubuntu Mono'),local('UbuntuMono-Regular'),url(https://fonts.gstatic.com/s/ubuntumono/v9/KFOjCneDtsqEr0keqCMhbCc-CsTYl4BOQ3o.woff2) format('woff2');
	unicode-range: U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116
}
@font-face {
	font-family: 'Ubuntu Mono';
	font-style: normal;
	font-weight: 400;
	src: local('Ubuntu Mono'),local('UbuntuMono-Regular'),url(https://fonts.gstatic.com/s/ubuntumono/v9/KFOjCneDtsqEr0keqCMhbCc2CsTYl4BOQ3o.woff2) format('woff2');
	unicode-range: U+1F00-1FFF
}
@font-face {
	font-family: 'Ubuntu Mono';
	font-style: normal;
	font-weight: 400;
	src: local('Ubuntu Mono'),local('UbuntuMono-Regular'),url(https://fonts.gstatic.com/s/ubuntumono/v9/KFOjCneDtsqEr0keqCMhbCc5CsTYl4BOQ3o.woff2) format('woff2');
	unicode-range: U+0370-03FF
}
@font-face {
	font-family: 'Ubuntu Mono';
	font-style: normal;
	font-weight: 400;
	src: local('Ubuntu Mono'),local('UbuntuMono-Regular'),url(https://fonts.gstatic.com/s/ubuntumono/v9/KFOjCneDtsqEr0keqCMhbCc0CsTYl4BOQ3o.woff2) format('woff2');
	unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF
}
@font-face {
	font-family: 'Ubuntu Mono';
	font-style: normal;
	font-weight: 400;
	src: local('Ubuntu Mono'),local('UbuntuMono-Regular'),url(https://fonts.gstatic.com/s/ubuntumono/v9/KFOjCneDtsqEr0keqCMhbCc6CsTYl4BO.woff2) format('woff2');
	unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD
}